import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	selectedImage: {
		images: [],
		alreadySelected: false,
	},
	products: [],
	purchaser_passcode: null,
};

export const selectedImageSlice = createSlice({
	name: "selectedImage",
	initialState,
	reducers: {
		updateProductImages: (state, action) => {
			const images = action.payload.selectedImage;
			const products = action.payload.products;
			images.forEach((image, index) => {
				state.selectedImage.images[index] = { ...state.selectedImage.images[index], products: products };
			});
		},

		selectImage: (state, action) => {
			// to check if image is already available
			const newImage = action.payload.item;
			const existingImage = state.selectedImage.images.find((image) => image.file_key === newImage.file_key);

			if (existingImage) {
				state.selectedImage.alreadySelected = true;
			} else {
				state.selectedImage.images.push({
					...newImage,
					products: action.payload.products,
				});
				state.selectedImage.alreadySelected = false;
			}
		},

		removeImage: (state, action) => {
			state.selectedImage.images = state.selectedImage.images.filter(
				(image) => image.file_key !== action.payload,
			);
			state.products = state.products.filter((image) => image.file_key !== action.payload);
		},

		resetAll: (state, action) => {
			state.selectedImage.images = [];
			state.products = [];
		},

		branchProductIncrement: (state, action) => {
			const { imageIndex, productIndex } = action.payload;

			let existingProduct = state.selectedImage.images[imageIndex].products[productIndex];
			existingProduct = {
				...existingProduct,
				quantity: existingProduct.quantity + 1 || 1,
			};

			state.selectedImage.images[imageIndex].products[productIndex] = existingProduct;
		},

		branchProductDecrement: (state, action) => {
			const { imageIndex, productIndex } = action.payload;

			let existingProduct = state.selectedImage.images[imageIndex].products[productIndex];
			existingProduct = {
				...existingProduct,
				quantity: existingProduct?.quantity > 1 ? existingProduct.quantity - 1 : 0,
			};

			state.selectedImage.images[imageIndex].products[productIndex] = existingProduct;
		},

		resetBranchProduct: (state, action) => {
			state.selectedImage.images.forEach((image, imageIndex) => {
				image.products.forEach((product, index) => {
					state.selectedImage.images[imageIndex].products[index] = {
						...product,
						quantity: 0,
					};
				});
			});
		},

		productIncrement: (state, action) => {
			const { image, item } = action.payload;

			const newProduct = {
				format: item.format,
				cost: item.price,
				totalCost: item.price,
				quantity: 1,
				file_url: encodeURI(image.file_url),
				file_key: image.file_key,
				file_url_watermark: image.file_url_watermark,
				is_potrait: image.is_potrait,
				photographer_id: image.photographer_id,
				file_url_thumbnail: image.file_url_thumbnail,
			};

			const existingProduct = state.products.find(
				(pd) => pd.format === newProduct.format && pd.file_key === newProduct.file_key,
			);

			if (existingProduct) {
				existingProduct.quantity++;
				existingProduct.totalCost = existingProduct.quantity * existingProduct.cost;
			} else {
				state.products.push(newProduct);
			}
		},

		productDecrement: (state, action) => {
			const { image, item } = action.payload;

			const productIndex = state.products.findIndex(
				(pd) => pd.format === item.format && pd.file_key === image.file_key,
			);

			if (!state.products[productIndex]) {
				return;
			}

			if (state.products[productIndex].quantity === 1) {
				state.products.splice(productIndex, 1);
			} else {
				state.products[productIndex].quantity -= 1;
				state.products[productIndex].totalCost =
					state.products[productIndex].quantity * state.products[productIndex].cost;
			}
		},
		resetProducts: (state, action) => {
			state.products = [];
		},

		setPassCode: (state, action) => {
			const purchaser_passcode = action.payload;
			state.purchaser_passcode = purchaser_passcode;
		},
	},
});

// Action creators are generated for each case reducer function
export const {
	updateProductImages,
	selectImage,
	removeImage,
	productIncrement,
	productDecrement,
	resetAll,
	branchProductIncrement,
	branchProductDecrement,
	setPassCode,
	resetBranchProduct,
	resetProducts,
} = selectedImageSlice.actions;

export default selectedImageSlice.reducer;
